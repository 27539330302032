<template>
    <v-row>
        <v-dialog v-model="dialog" scrollable>
            <v-card outlined max-width="575">
                <v-card-title class="text-h5">{{tituloDialog}} - {{dadosDialog}}</v-card-title>
                <v-card-text>
                    <v-divider></v-divider>
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th>...</th>
                                <th>...</th>
                                <th>...</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>...</td>
                                <td>...</td>
                                <td>...</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog = false">Fechar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-col cols="12" md="12">
            <v-card>
                <v-card-text>
                    <v-form>
                        <v-row class="mb-n12">
                            <v-col class="pl-2 pr-1 pl-lg-3 d-none d-md-block">
                                <InputDatePicker
                                    :disabled="carregando"
                                    v-model="busca.dtinicio"
                                    label="Data inicial"
                                    :outlined="true"
                                    :dense="true"
                                />
                            </v-col>
                            <v-col class="d-none d-md-block px-1">
                                <InputDatePicker
                                    :disabled="carregando"
                                    v-model="busca.dtfim"
                                    label="Data final"
                                    :outlined="true"
                                    :dense="true"
                                />
                            </v-col>
                            <v-bottom-sheet class="d-inline-flex d-md-none" v-model="sheet">
                                <v-sheet class="text-center" height="100%">
                                    <v-row class="pa-3">
                                        <v-col cols="6">
                                            <InputDatePicker
                                                :disabled="carregando"
                                                v-model="busca.dtinicio"
                                                label="Data inicial"
                                                :outlined="true"
                                                :dense="true"
                                            />
                                        </v-col>
                                        <v-col cols="6">
                                            <InputDatePicker
                                                :disabled="carregando"
                                                v-model="busca.dtfim"
                                                label="Data final"
                                                :outlined="true"
                                                :dense="true"
                                            />
                                        </v-col>
                                        <v-col cols="6">
                                            <v-btn
                                                text
                                                class="mt-n3"
                                                color="primary"
                                                @click="sheet = !sheet"
                                            >Cancelar</v-btn>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-btn
                                                text
                                                class="mt-n3"
                                                color="primary"
                                                @click="filtrar(0), sheet = !sheet"
                                            >Filtrar</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-sheet>
                            </v-bottom-sheet>
                            <v-col class="px-1 d-flex">
                                <v-autocomplete
                                    class="d-inline-flex d-md-none"
                                    no-filter
                                    v-model="busca.busca"
                                    outlined
                                    dense
                                    clearable
                                    :search-input.sync="searchMobile"
                                    :items="items"
                                    :loading="carregando"
                                    item-text="nome"
                                    item-value="idcliente"
                                    append-outer-icon="mdi-menu"
                                    @click:append-outer="sheet = !sheet"
                                    label="Cliente"
                                ></v-autocomplete>
                                <v-autocomplete
                                    class="d-none d-md-block"
                                    no-filter
                                    v-model="busca.busca"
                                    outlined
                                    dense
                                    clearable
                                    :search-input.sync="search"
                                    :items="items"
                                    :loading="carregando"
                                    item-text="nome"
                                    item-value="idcliente"
                                    label="Cliente"
                                ></v-autocomplete>
                                <v-btn
                                    :disabled="carregando"
                                    class="mx-2 mt-1"
                                    color="primary"
                                    elevation="0"
                                    fab
                                    x-small
                                    @click="filtrar(0)"
                                >
                                    <v-icon>mdi-magnify</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <span class="text-caption text--disabled pl-3">* Atualizado em: 10:52:34 14/12/2021</span>
            </v-card>
        </v-col>
        <v-col cols="12" md="12">
            <v-row>
                <v-col>
                    <CardDashboard :loading="carregando" title="Último produto comprado">
                        <template>Cimento</template>
                    </CardDashboard>
                </v-col>
                <v-col>
                    <CardDashboard :loading="carregando" title="Produto mais comprado">
                        <template>Tijolo</template>
                    </CardDashboard>
                </v-col>
                <v-col>
                    <CardDashboard :loading="carregando" title="Última compra">
                        <template>27/08/2021</template>
                    </CardDashboard>
                </v-col>
                <v-col>
                    <CardDashboard :loading="carregando" title="Compras últ. 90 dias">
                        <template>R$ 2.358,45</template>
                    </CardDashboard>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" md="4">
            <v-card>
                <v-card-title
                    class="d-inline-block col-12 text-truncate"
                >Vendas por prazo de pagamento</v-card-title>
                <v-card-text>
                    <GraficoPieDashboard
                        :dataSeries="[1235, 825]"
                        :labels="['à vista', 'à prazo']"
                    />
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" md="4">
            <v-card>
                <v-card-title
                    class="d-inline-block col-12 text-truncate"
                >Vendas por forma de pagamento</v-card-title>
                <v-card-text>
                    <GraficoPieDashboard
                        :dataSeries="[134, 238, 358, 58, 122]"
                        :labels="['Dinheiro', 'Cartão Débito', 'Cartão Crédito', 'Cheque', 'Depósito']"
                    />
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" md="4">
            <v-card>
                <v-card-title class="d-inline-block col-12 text-truncate">Vendas por tipo de entrega</v-card-title>
                <v-card-text>
                    <GraficoPieDashboard
                        :dataSeries="[738, 862, 351]"
                        :labels="['Retira na loja', 'Entrega depósito', 'Aguardando']"
                        palette="palette3"
                    />
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" md="12">
            <v-row>
                <v-col
                    @click="dialogConsulta('Vendas em aguardando pendentes', 'dados')"
                    style="cursor: pointer;"
                >
                    <CardDashboard
                        :loading="carregando"
                        :title="`Vendas em aguardando pendentes: ${5}`"
                    >
                        <template>R$ 2.782,25</template>
                    </CardDashboard>
                </v-col>
                <v-col
                    @click="dialogConsulta('Contratos pendentes de assinatura', 'dados')"
                    style="cursor: pointer;"
                >
                    <CardDashboard
                        :loading="carregando"
                        :title="`Contratos pendentes de assinatura`"
                    >
                        <template>3</template>
                    </CardDashboard>
                </v-col>
                <v-col
                    @click="dialogConsulta('Compras em atraso', 'dados')"
                    style="cursor: pointer;"
                >
                    <CardDashboard :loading="carregando" :title="`Compras em atraso: ${7}`">
                        <template>R$ 498,12</template>
                    </CardDashboard>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import InputDatePicker from "../Widgets/InputDatePicker";
import CardDashboard from "../Widgets/CardDashboard";
import GraficoPieDashboard from "../Widgets/GraficoPieDashboard";

export default {
    components: { InputDatePicker, CardDashboard, GraficoPieDashboard },
    name: "ClienteDashboardVendas",
    data: () => ({
        sheet: false,
        dialog: false,
        tituloDialog: "",
        dadosDialog: {},
        carregando: false,
        filiais: {},
        busca: {
            dtinicio: `${new Date().getFullYear()}-${
                (new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 1,
                    0
                ).getMonth() +
                    1 <
                10
                    ? "0"
                    : "") +
                (new Date().getMonth() + 1)
            }-01`,
            dtfim: `${new Date(
                new Date().getFullYear(),
                new Date().getMonth() + 1,
                0
            ).getFullYear()}-${
                (new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 1,
                    0
                ).getMonth() +
                    1 <
                10
                    ? "0"
                    : "") +
                (new Date().getMonth() + 1)
            }-${new Date(
                new Date().getFullYear(),
                new Date().getMonth() + 1,
                0
            ).getDate()}`,
            idempresa: 0,
        },

        aguardarBusca: "",
        nomeLimit: 50,
        clientes: [],
        search: null,
        searchMobile: null,
    }),
    computed: {
        ...mapState(["backendUrl", "pgLimit", "timeLimit", "usuario"]),
        items() {
            if (this.clientes == null) return;

            this.clientes.forEach((cliente) => {
                if (cliente.nome.length > this.nomeLimit) {
                    cliente.nome =
                        cliente.nome.slice(0, this.nomeLimit) + "...";
                }
            });

            return this.clientes;
        },
    },
    methods: {
        dialogConsulta(titulo, dados) {
            this.dialog = true;
            this.tituloDialog = titulo;
            this.dadosDialog = dados;
        },
        filtrar() {},
        carregarClientes(val) {
            clearTimeout(this.aguardarBusca);

            this.aguardarBusca = setTimeout(() => {
                this.carregando = true;
                return axios
                    .post(`${this.backendUrl}cliente/listar`, {
                        limit: 30,
                        offset: 0,
                        busca: val || null,
                    })
                    .then((res) => {
                        this.clientes = res.data.lista;
                        this.carregando = false;
                    })
                    .catch(() => {
                        this.carregando = false;
                    });
            }, this.timeLimit);
        },
    },
    watch: {
        "busca.dtfim": function () {
            if (this.busca.dtfim < this.busca.dtinicio) {
                this.busca.dtinicio = this.busca.dtfim;
            }
        },
        "busca.dtinicio": function () {
            if (this.busca.dtfim < this.busca.dtinicio) {
                this.busca.dtfim = this.busca.dtinicio;
            }
        },
        search: function (val) {
            this.carregarClientes(val);
        },
        searchMobile: function (val) {
            this.carregarClientes(val);
        },
    },
    created() {
        this.busca.idempresa = this.usuario.idempresa;
    },
};
</script>

<style scoped>
</style>